import React, {useState} from "react";

import {useLocation} from 'react-router-dom';

import {getDatabase, ref, child, get, set, onValue, remove} from "firebase/database";

import { useNavigate } from "react-router-dom";
import style from './Product.module.css'

export default function Checkout() {

  

  const navigate = useNavigate();
    const [dDate, setdDate] = useState();

    const [charge, setCharge] = useState();

    const [tt, setTotal] = useState();

    const [hh, sethh] = useState("");
    const [aa, setaa] = useState("");
    const [pp, setpp] = useState("");
    const [cc, setcc] = useState("");
    const [nn, setnn] = useState("");

    const location = useLocation();



    // get delivery date

const dbRef = ref(getDatabase());
get(child(dbRef, `PinCode/301001/del`)).then((snapshot) => {
  if (snapshot.exists()) {
    console.log(snapshot.val());
    setdDate(snapshot.val());

  } else {
    console.log("No data available");
  }
}).catch((error) => {
  console.error(error);
});


// get Delivery Charge


let total =0;

const chargeRef = ref(getDatabase());
get(child(chargeRef, `Charge/301001/charge`)).then((snapshot) => {
  if (snapshot.exists()) {

    const ch = snapshot.val();
  
    setCharge(ch);



    let sub = parseFloat(location.state.amount).toFixed(2);
      
        total = +sub + +ch;

        //console.log(total);
        setTotal(total);
  

  } else {
    console.log("No data available");
  }
}).catch((error) => {
  console.error(error);
});






const houseNumber = event =>{

  sethh(event.target.value)

} 


const areaGet = event =>{

  setaa(event.target.value)

} 



const pinGet = event =>{

  setpp(event.target.value)

} 


const cityGet = event =>{

  setcc(event.target.value)

} 


const noteGet = event =>{

  setnn(event.target.value)

} 






function checkout()
{



  const d = new Date();
  let ms = d.getTime();
  
  
  placeOrder(ms);



}




function placeOrder(ms)
{



var currentDate = new Date()
var day = currentDate.getDate()
var month = currentDate.getMonth() + 1
var year = currentDate.getFullYear()


if (day < 10) day = '0' + day;
if (month < 10) month = '0' + month;


var currentDate = day + "-" + month + "-" + year

var ttfinal = parseFloat(tt).toFixed(2).toString();






const dbch = getDatabase();
set(ref(dbch, 'NewOrders/'+ms), {
  phone: "+917800110011",
     name: "Amit",
     address : hh+" "+aa,
     total: ttfinal,
     status: "0",
     comment: nn,
     timeSlot: currentDate,
     pinCode: pp,
     city: cc,
     paymentMethod: "COD",
     latLng: "0,0",
     type: "Grocery",
     orderAmount: ttfinal,
     refund: "",
     useWallet: "",
     charges: charge,
     orderDate: currentDate
});




const db = getDatabase();



 /*   const from = firebase.database().ref('MyCart/+917800110011');
   const to = firebase.database().ref('Requests/'+ms+'/foods') */

   MoveCart(ms);


   

}



function MoveCart(ms)
{


  const dbRe = getDatabase();

  //const from = ref(dbRe, 'MyCart/+917800110011');



  onValue(ref(dbRe, 'MyCart/+917800110011'), (snapshot) => {
  

    
    set(ref(dbRe, 'NewOrders/'+ms+'/foods'), snapshot.val()).then(() => {
  
    
      navigate('/thankyou');

  
      
      
    })
    .catch((error) => {
      
     
      alert(error);
      
    });


  });






 /*   oldRef.onValue('value', function(snap)  {
       newRef.set( snap.val(), function(error) {
            if( !error ) {  oldRef.remove(); }
            else if( typeof(console) !== 'undefined' && console.error ) {  console.error(error); }
       });
  }); */

}










  return (
    <>


<section className="main-section-ch">


<div className="grand-row-ch">


    <img src="Images/v_logo.png" alt=""/>
    <h2>Checkout</h2>


    <div className="line"></div>


    <div className="row-ch">

        <div className="column-ch">
         


          
          

    
            <div className="shopping-cart-ch">

  
                <div className="box-ch">

                  <h4>Delivery Info:</h4>


                
                  <p id="delivery-date">{dDate}</p>

                    <h4>Delivery Address:</h4>
                    
                
                           
                    <input onChange={houseNumber} type="text" id="house" name="houseNo" placeholder="House No./Building Name"/>
                  
                    <input onChange={areaGet} type="text" id="area" name="address" placeholder="Area / Colony / Road Name"/>
                
                    <input onChange={cityGet} type="text" id="city" name="city" placeholder="City"/>
    
                     
                    <input onChange={pinGet} type="text" id="pin" name="zip" placeholder="pin code"/>
                     


                    <h4>Delivery Note:</h4>
                 
                
                    <textarea onChange={noteGet} rows="5" id="note" placeholder="Any Note..."></textarea>
               
              

    
          
            
            </div>

        </div>

        </div>
        <div className="column-2-ch">
            


<div className="main-subtotal-ch">
           
            
        
            <div className="subtotal-ch">

                <h4>Payment Info:</h4>
            
        


            <div className="total-price">
            <table>
              <tr>
                <td>Subtotal</td>
                <td id="subtotal"> ₹{parseFloat(location.state.amount).toFixed(2)}</td>
              </tr>

              <tr>
                <td>Charges</td>
                <td id="charges"> ₹{parseFloat(charge).toFixed(2)}</td>
              </tr>

              <tr>
                <td>Payable Amount</td>
                <td id="payable">₹{parseFloat(tt).toFixed(2)}</td>
              </tr>
            </table>


          </div>

       

            

      


            <div className="wrapper">
                <input type="radio" name="select" id="option-1" checked/>
                <input type="radio" name="select" id="option-2"/>
                  <label htmlFor="option-1" className="option option-1">
                    <div className="dot"></div>
                     <span>COD</span>
                     </label>
                  <label htmlFor="option-2" className="option option-2">
                    <div className="dot"></div>
                     <span>Online</span>
                  </label>
               </div>




            <button className={style.btncheck} id="checkout-btn" onClick={() => checkout()} >Place Order</button>
        
      


        </div>

        </div>
        
      </div>

      </div>
    
 
  </div>





</section>
      
    </>
  )
}
