import React, { useState, useEffect} from "react";

import {useLocation} from 'react-router-dom';
import { getDatabase, ref, set, onValue, remove } from "firebase/database";

import { useNavigate } from "react-router-dom";


import style from './Product.module.css'


export default function Cart() {


    const navigate = useNavigate();
    

    const [allCart, setCart] = useState([]);

    const [count, setCount] = useState("0");

    const [total, setTotal] = useState(0);

    useEffect(() => {
   
 
        getData();
    
        console.log(allCart);
    
       }, []);



       
       async function getData() {

        const item = [];



        const db = getDatabase();
const dbRef = ref(db, 'MyCart/+917800110011');


let t1 =0;
    
onValue(dbRef, (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();


      let size = snapshot.size;
    
      setCount(size);

      setCart((prev) =>{
        return[...prev,childData];
      })

      item.push(childData);
      setCart(item);

      


      const amount = childData.price;

     var t = parseFloat(amount); 
    
     t1 += t;

     setTotal(t1);
    

      
    });
  }, {
    onlyOnce: true
  });
  
 
        };




        function DeleteProduct(id)
        {





         
          const db = getDatabase();
          
          remove(ref(db, 'MyCart/+917800110011/'+id));
    
    

          getData();



        }





        function GoCheckout()
        {

            navigate('/checkout',{state:{amount:total}});

            
        }






  return (
    <>


    {/* Header Section */}

<header className="header">
    <a href="#" className="logo">
      
        <img src="./Images/logo.png" alt=""/>
    </a>


    <form className="search-form">
        <input type="text" id="search-box" placeholder="Search for products"/>
        <label htmlFor="search-box " className="fa fa-search"></label>
    </form>

  

    <div className="icons">

        <div className="fa fa-bars" id="menu-btn"></div>
        <div className="fa fa-search" id="search-btn"></div>
        <div className="fa fa-shopping-cart" id="cart-btn"></div>
        <div className="fa fa-user" id="login-btn"></div>

    </div>







</header>







<section className="main-section">


<div className="grand-row">


    <h2>Cart</h2>

    <div className="line"></div>

<div className="row">

    <div className="column">








    <div className="shopping-cart" id="cartDiv">


    {allCart.map((cpro) =>{


return(

    <>


        <div className="box">

<i className="fa fa-trash" onClick={()=> DeleteProduct(cpro.productId)}></i>
<img src={cpro.image} id="product-img"/>
<div className="content">
    <h3>{cpro.productName}</h3>
    <span className="price">₹{cpro.price} | {cpro.weight}</span>
   

    <div className="quantity buttons_added">
    <input type="button" value="-" className="minus"/>
  <input type="number" step="1" min="1" max="5" name="quantity" value="1" title="Qty" className="input-text qty text" size="4" pattern="" inputmode="" readonly/>
  <input type="button" value="+" className="plus" onclick="updateQuantity('${dataP.productId}',)"/>
</div>
</div>
</div>


      


        </>

)
})}



</div>





    </div>
    <div className="column-2">
        
        
        <div className="subtotal">
        
        <span id="subtotal-cart">Subtotal ({count}) item:</span>
        
        <span id="subtotal-amount">₹{total}</span>



        <button className={style.btncheck} id="checkout-btn" onClick={() => GoCheckout()}>Proceed to Checkout</button>
    
        <h4 id="con-shopping-btn">Continue to Shopping</h4>


    </div>

    </div>
    
  </div>


</div>



</section>

      
    </>
  )
}
