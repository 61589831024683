import React, { useState, useEffect, useRef} from "react";
import './Header.css'


export default function Header() {

    const [getSearch, setSearch] = useState('');

  return (
    <div>

    


<header className="header">
    <a href="#" className="logo">
      
        <img src="./Images/v_logo.png" alt=""/>
    
    </a>


    {/* Location Box */}

    <div className="loc-box">

        <h3>Location <i class="fa fa-angle-down"></i></h3>
        <p>Delivery in 60 min.</p>


    </div>



    {/* Search Box */}

    <div className="search-d">
    
    <input type="text" className="search-in" placeholder="Search Essential items..." defaultValue={getSearch} onChange={(e)=> setSearch(e.target.value)}/>

    <img id="search-icon" src="Images/search.png"></img>
</div>




 {/* Icons Box */}
  

    <div className="icons">

        <div className="fa fa-bars" id="menu-btn"></div>
        <div className="fa fa-search" id="search-btn"></div>
        <div className="fa fa-shopping-cart" id="cart-btn"></div>
        <div className="fa fa-user" id="login-btn"></div>

    </div>







</header>


 {/* Search Box */}

 <div className="search-dm">
    
    <input type="text" className="search-in" placeholder="Search Essential items..." defaultValue={getSearch} onChange={(e)=> setSearch(e.target.value)}/>

    <img id="search-icon" src="Images/search.png"></img>
</div>

      
    </div>
  )
}
