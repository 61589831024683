
import './App.css';
import Home from "./Pages/Home"
import CategoryPage from './Pages/Categories'
import ProductList from './Pages/Products'
import ProductDetail from './Pages/ProductDetail'

import { Routes, Route, Router, Link, HashRouter } from 'react-router-dom';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import Thankyou from './Pages/Thankyou';
import Header from './Pages/Header';
import Footer from './Pages/Footer'

function App() {
  return (
    <>

 




<Header/>

<Routes>
    


        <Route  path="/" exact element={<Home />} />
        <Route path="/category" exact element={<CategoryPage />} />
        <Route path="/products" exact element={<ProductList />} />
        <Route path="/cart" exact element={<Cart />} />
        <Route path="/checkout" exact element={<Checkout />} />
        <Route path="/thankyou" exact element={<Thankyou />} />
        <Route path="/detail" exact element={<ProductDetail />} />

       
      
      </Routes> 


<Footer/>
    
  

    </>
  );
}

export default App;
