import React from 'react'
import './Header.css'

export default function Footer() {
  return (
    <div className='footer-div'>

        <span> &copy; 2023 <b>VEGITA</b> | All Rights Reserved.</span>


      
    </div>
  )
}
