import React from 'react'

export default function ProductDetail() {



    console.log("Okay");
  return (
    <div>
      
    </div>
  )
}
