import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes } from 'react-router-dom';
import { hydrate, render } from 'react-dom';




const App = (

  <React.StrictMode>
   
  <BrowserRouter>

   <Root />
 

   </BrowserRouter>
 </React.StrictMode>

);



//const rootElement = ReactDOM.createRoot(document.getElementById('root'));

const rootElement = document.getElementById('root');


if (rootElement.hasChildNodes()) {
  hydrate(App, rootElement);
} else {
  render(App, rootElement);
}

/* root.render(

 
  <React.StrictMode>
   
   <BrowserRouter>
 
    <App />
  
 
    </BrowserRouter>
  </React.StrictMode>

); */






// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
