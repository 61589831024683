
import {useLocation} from 'react-router-dom';
import React, { useState, useEffect} from "react";
import { fires } from "../firebase";
import { getDatabase, ref, set } from "firebase/database";

import { collection, query, where, getDocs } from "firebase/firestore"; 
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function Products() {

  const params = useParams();

    const location = useLocation();

    const navigate = useNavigate();


    const [allPro, setPro] = useState([]);




    function notify(name){


      toast.success("Added! "+name, {autoClose:1500});
   /*    // Set to 10sec
      toast.warning('Danger', {autoClose:10000})
      // Set to 3sec
      toast.success('successful', {autoClose:3000})
      // User have to close it
      toast.info('GeeksForGeeks', {autoClose:false})
      toast.error('Runtime error', {
       // Set to 15sec
       position: toast.POSITION.BOTTOM_LEFT, autoClose:15000})
      toast('Hello Geeks')// Default */
        
  }











    useEffect(() => {
   
 
        getData();
    
    
       }, []);

       



       async function getData() {

        const item = [];

        const prodRef = collection(fires, "Foods");

        const q = query(prodRef, where("menuId", "==", location.state.id));

        const querySnapshot = await getDocs(q);
  
 
        querySnapshot.forEach((doc) => {
      
      
          setPro((prev) =>{
            return[...prev,doc.data()];
          })
  
          item.push(doc.data());
          setPro(item);
        
        
        });
  
          
        
  
      };



  




      function AddToCart(name,id,price,weight,image)
      {


        notify(name);
      

          // var phone = window.localStorage.getItem("phone");
   var ph = "+917800110011";

   if(ph.length<1)
   {
       //window.location.href = 'login.html';
       //window.alert("added to Cart Not");
      

   }
   else
   {
      

      WriteCart(ph,name,id,price,weight,image);

   }
      }







      function WriteCart(phone, name, id, price, weight, image)
      {

   


    const product = {

    userPhone: phone,
    productId: id,
    image : image,
    weight: weight,
    price: price,
    productName: name,
    quantity: "1"
    }


  
    const dbCart = getDatabase();
    set(ref(dbCart, 'MyCart/' + phone+'/'+id),product);
 


}




function OpenCart()
{


  navigate('/cart');
}
  


function goTODetail()
{

  navigate('/detail');

}


 
 
  return (



    <div>
   



{/* category name */}




<section className="category-heading" id="category-headline">


<h2 id="heading">Products of <span>{location.state.name}</span></h2>

</section>









{/* Product List Section */}







<div className="product-list-container" id="productDiv">




{allPro.map((prod) =>{


return(



    <>





<div className="box">
     
  
     
     
     <img src={prod.image} alt="" id="product-img" className="product-image" onClick={()=> goTODetail()} />
   
     <div className="box-text">
     <p className="title" id="name">{prod.name}</p>
     <span className="price">₹{prod.price}</span>
              <span className="mrp">₹{prod.amount}</span>
              <span className="weight"> | {prod.weight}</span>
          
         
              </div>

              <div className='btn-cart' onClick={() => AddToCart(prod.name,prod.id,prod.price,prod.weight,prod.image)}>
             
             Add to Cart


             </div>


             
    
   </div>


   <ToastContainer />




  







  </>

)
})}



</div>




    </div>
  )
}
