import React from "react";
import "./ShimmerStyle.css";

export default function DealShimmer() {
  return (
    <>
      <div className="banner-shimmer">
        <div className="bann-shimmer"></div>
      </div>

      <section className="heading-shimmer">
      <div className="bann-shimmer-heading"></div>
        </section>

      <div className="deal-shimmer">
        {Array.from({ length: 3 }).map((el, index) => {
          return <div className="card-shimmer">
             <div className="bann-shimmer-card"></div>
          </div>;
        })}
      </div>



      <section className="heading-shimmer">
      <div className="bann-shimmer-heading"></div>
        
      </section>

      <div className="container-cat-shimmer">

      {Array.from({ length: 27 }).map((el, index) => {
          return <div className="categoryCard-shimmer">
          
          <div className="main-shimmer"></div>
          <div className="sub-shimmer">
            
          </div>
  
         
    
            
          </div>
        })}





        
      </div>
    </>
  );
}
