import React from 'react'


import {getDatabase, ref, child, get, set, onValue, remove} from "firebase/database";





export default function Thankyou() {



     const db = getDatabase();

    const fr = ref(db, 'MyCart/+917800110011');


    remove(fr); 


  return (
    <div>
      
    </div>
  )
}
