

import { initializeApp } from 'firebase/app';
import { getFirestore} from "@firebase/firestore";
import { getAuth } from "firebase/auth";



// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD-EPyedyn7k6qKOu6HR3hN6zEyYlRtnFE",
    authDomain: "live-wallpaper-app-7dec8.firebaseapp.com",
    databaseURL: "https://live-wallpaper-app-7dec8.firebaseio.com",
    projectId: "live-wallpaper-app-7dec8",
    storageBucket: "live-wallpaper-app-7dec8.appspot.com",
    messagingSenderId: "36934831750",
    appId: "1:36934831750:web:5121d8666d4f567fae8b07",
    measurementId: "G-H23NSGW9P9"
  };

  const app = initializeApp(firebaseConfig);

  export const fires = getFirestore(app);

export const auth = getAuth(app);
export default app;