import React, { useState, useEffect } from "react";
import { fires } from "../firebase";

import { collection, getDocs, where, query } from "firebase/firestore";

import "./Home.css";

import { useNavigate } from "react-router-dom";
import { getDatabase, ref, onValue } from "firebase/database";
import DealShimmer from "../Shimmer/DealShimmer";



function Home() {
  const [allCat, setcat] = useState([]);
  const [allDeal, setDeal] = useState([]);
  const [allTs, setTs] = useState([]);
  const [allBottom, setBottom] = useState([]);
  const [catOfferBanner, setCatOfferBanner] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    getData();
    getDeals();
    getTs();
    getMiddleBanner();
    getBottoms();

  
  }, []);

  // getData();

  async function getData() {
    const item = [];

    //const querySnapshot = await getDocs(collection(fires, "Category"));

    
    const museums = query(collection(fires, 'Category'), where('pr', '>=', 1));
const querySnapshot = await getDocs(museums);
    querySnapshot.forEach((doc) => {
      //console.log(`${doc.id} => ${doc.data()}`);

      setcat((prev) => {
        return [...prev, doc.data()];
      });

    /*   item.push(doc.data());
      setcat(item); */
    });
  }


  async function getDeals() {
    const item = [];

    //const querySnapshot = await getDocs(collection(fires, "Foods"));

    const museums = query(collection(fires, 'Foods'), where('dealPr', '>=', 1));
const querySnapshot = await getDocs(museums);


    querySnapshot.forEach((doc) => {
      


      item.push(doc.data());
     
      setDeal(item);

    
    });
  }


  async function getTs() {
    const item = [];

    //const querySnapshot = await getDocs(collection(fires, "Foods"));

    const museums = query(collection(fires, 'Foods'), where('bestSell', '>=', 1));
const querySnapshot = await getDocs(museums);


    querySnapshot.forEach((doc) => {
      //console.log(`${doc.id} => ${doc.data()}`);

      setTs((prev) => {
        return [...prev, doc.data()];
      });

     // item.push(doc.data());
     
     // setcat(item);
    });
  }

  async function getMiddleBanner()
  {

    const database = getDatabase();
    const bannerRef = ref(database, 'MiddleBanner/image');
 onValue(bannerRef, (snapshot) => {
  const data = snapshot.val();
  setCatOfferBanner(data);

});

  }


  async function getBottoms() {
    const item = [];

    //const querySnapshot = await getDocs(collection(fires, "Category"));

    
    const museums = query(collection(fires, 'Bottoms'), where('pr', '>=', 1));
const querySnapshot = await getDocs(museums);
    querySnapshot.forEach((doc) => {
      //console.log(`${doc.id} => ${doc.data()}`);

      setBottom((prev) => {
        return [...prev, doc.data()];
      });

    /*   item.push(doc.data());
      setcat(item); */
    });
  }



  function check(id, name) {
    navigate("/products/", { state: { id: id, name: name } });
  }


  function scrollLeft() {
    const left = document.getElementById("dealDiv");
    left.scrollBy(380, 0);
   
  }
  function scrollRight() {
    const right = document.getElementById("dealDiv");
    right.scrollBy(-380, 0);

   
  }


  var container = document.getElementById("dealDiv");

  

  if(allDeal.length==0)
  {
    return <DealShimmer/> 
    
  }
  

  return (
    <>
      {/* banner section */}

  

      <section className="home" id="home">
        <div className="content">
          <h3>
            Groceries <span>delivery</span> at your home
          </h3>
          <p>Get daily groceries at your home. Free delivery fast delivery</p>
        </div>
      </section>





           {/* Deal of the day */}

           <section className="category-heading" id="category-headline">
        <h2 id="heading">
          Deal of <span>The Day</span>
        </h2>
      </section>

  

      <div id="dealDiv" className="container-deal">

 {/*      <img className="left-scroll" src=".\Images\left.png" onClick={()=> scrollLeft()}></img> */}

        {allDeal.map((deal,index) => {
          if(deal.dealImage!=null){

          
          return (
            <div key={index}>
              <div
                className="dealCard"
            
                onClick={() => check(deal.id, deal.name)}
              >
                <img src={deal.dealImage} alt="" />
              
              </div>
            </div>
          );
        }
        })}

{/* <img className="right-scroll" src=".\Images\right.png" onClick={()=> scrollRight()}></img> */}
      </div>



      {/* Category section */}

      <section className="category-heading" id="category-headline">
        <h2 id="heading">
          Shop by <span>Category</span>
        </h2>
      </section>

      <div id="categoryDiv" className="container-cat">
        {allCat.map((cat,index) => {
          return (
            <div key={index}>
              <div
                className="categoryCard"
                onClick={() => check(cat.id, cat.name)}
              >
                <img src={cat.image} alt="" />
                <p className="title" id="name">
                  {cat.name}
                </p>
              </div>
            </div>
          );
        })}
      </div>

 




      {/* Top Selling Products */}

      <section className="category-heading" id="category-headline">
        <h2 id="heading">Top Selling <span>Products</span>
        </h2>
      </section>


      <div className="product-list-container-home" >




{allTs.map((ts, index) =>{
  


return(



    <div key={index}>





<div className="box" >
     

     
     <img src={ts.image} alt="" id="product-img" className="product-image" />
   
     <div className="box-text">
     <p className="title" id="name">{ts.name}</p>
     <span className="price">₹{ts.price}</span>
              <span className="mrp">₹{ts.amount}</span>
              <span className="weight"> | {ts.weight}</span>
          
              </div>


             
   </div>


  </div>

)
})}



</div>





   {/* Offer on Category */}




      <img className="cat-offer" src={catOfferBanner}></img>




  {/* Bottom elements */}




  <div id="categoryDiv" className="container-bottom">
        {allBottom.map((bottom,index) => {
          return (
            <div key={index}>
              <div
                className="bottomCard"
                
              >
                <img src={bottom.image} alt="" />
                <p className="title" id="name">
                  {bottom.title}
                </p>

                <p className="subtitle" id="name">
                  {bottom.detail}
                </p>
              </div>
            </div>
          );
        })}
      </div>

    </>
  );
}

export default Home;
